<template>

    <b-overlay :show="loading"
               :variant="variant"
               :opacity="opacity"
               :blur="blur"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-large
               class="loaderPreview"
               rounded="sm">
        <section v-if="dataLoaded">
            <b-row v-if="(meta.breadcrumb || meta.pageTitle)"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="6">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                {{ meta.pageTitle }}
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                       :key="item.text"
                                                       :active="item.active"
                                                       :to="item.to">
                                        {{ item.text }}
                                    </b-breadcrumb-item>
                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- Content Right -->
                <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="6"
                       cols="12">

                </b-col>
            </b-row>

            <div class="post">
                <div v-if="error" class="error">
                    {{ error }}
                </div>
            </div>
            <b-card-code>

                <validation-observer ref="bulkEmailRules" v-if="dataLoaded">
                    <form ref="bulkEmailForm" id="bulkEmailForm" @submit.stop.prevent>
                        <b-row v-if="bulkEmail">
                            <b-col lg="12" class="text-right" v-if="bulkEmail.IsScheduled">
                                <b-button :to="'/sendemails'"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="secondary"
                                          class="btn-icon mr-1">
                                    <feather-icon icon="ArrowLeftCircleIcon" size="18" />
                                    Go Back
                                </b-button>
                            </b-col>

                            <b-col lg="12" class="text-right" v-if="!bulkEmail.IsScheduled">
                                <b-button :to="'/sendemails'"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="secondary"
                                          class="btn-icon mr-1">
                                    <feather-icon icon="XCircleIcon" size="18" />
                                    Cancel
                                </b-button>

                                <b-button @click="openSendTestEmailModal"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="info"
                                          type="button"
                                          class="mr-1">
                                    <feather-icon icon="MailIcon" size="18" />
                                    Send Test Email
                                </b-button>

                                <b-button @click="saveDraftEmail"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="primary"
                                          type="button"
                                          class="mr-1">
                                    <feather-icon icon="SaveIcon" size="18" />
                                    Save a Draft
                                </b-button>

                                <b-button @click="scheduleEmail"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="primary"
                                          type="button"
                                          class="mr-1">
                                    <feather-icon icon="SendIcon" size="18" />
                                    Save & Schedule
                                </b-button>
                            </b-col>

                            <b-col lg="6">
                                <b-form-group>
                                    <label for="SMTPConfigId">Select Smtp Configuration<span class="required"> *</span></label>


                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="SMTPConfigId">
                                        <select class="form-control"
                                                name="SMTPConfigId"
                                                id="SMTPConfigId"
                                                v-model="bulkEmail.SMTPConfigId"
                                                :state="errors.length > 0 ? false : null">
                                            <option value="0">
                                                Use System Default ({{ trimmedEmailText }})
                                            </option>
                                            <option v-for="smtp in userSmtpConfigurations"
                                                    :value="smtp.Id">
                                                {{ smtp.Title + "(" + smtp.FromEmail + ")" }}
                                            </option>
                                        </select>
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="6">
                                <b-form-group>
                                    <label for="ProjectIds">Select Project<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Projects">
                                        <multiselect name="ProjectIds"
                                                     id="ProjectIds"
                                                     track-by="Id"
                                                     label="Name"
                                                     v-model="selectedProjects"
                                                     :options="userFunnels"
                                                     key="Id"
                                                     value="Id"
                                                     return-object
                                                     multiple
                                                     :class="errors.length > 0 ? 'error' : 'valid'"
                                                     :state="errors.length > 0 ? false : null"></multiselect>
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>

                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label for="FromName">From Name<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="From Name">
                                        <b-form-input id="FromName"
                                                      name="FromName"
                                                      v-model="bulkEmail.FromName"
                                                      placeholder="Please Enter From Name"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label for="ReplyToEmail">Reply To Email<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required|email"
                                                         name="Reply To Email">
                                        <b-form-input id="ReplyToEmail"
                                                      name="ReplyToEmail"
                                                      v-model="bulkEmail.ReplyToEmail"
                                                      placeholder="Please Enter Reply To Email"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <div class="demo-inline-spacing">
                                        <b-form-radio id="Immediate"
                                                      value="false"
                                                      name="IsDelayed"
                                                      v-model="bulkEmail.IsDelayed"
                                                      @change="isImmediate = 'true'">
                                            Immediate
                                        </b-form-radio>
                                        <br />
                                        <b-form-radio id="Delayed"
                                                      value="true"
                                                      name="IsDelayed"
                                                      v-model="bulkEmail.IsDelayed"
                                                      @change="isImmediate = 'false'">
                                            Delayed
                                        </b-form-radio>
                                    </div>
                                </b-form-group>
                            </b-col>

                            <b-col lg="4" v-if="isImmediate == 'false'">
                                <b-form-group>

                                    <label for="TimeZoneValue">Time Zone<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="TimeZoneValue">
                                        <select class="form-control"
                                                id="TimeZoneValue"
                                                name="TimeZoneValue"
                                                v-model="bulkEmail.TimeZoneValue"
                                                :state="errors.length > 0 ? false : null"
                                                @change="onTimeZoneChange($event)">
                                            <option :value="tz.value"
                                                    v-for="tz in timeZones"
                                                    :data-offset="tz.offset"
                                                    :key="tz.value">
                                                {{ tz.text }}
                                            </option>
                                        </select>
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="4" v-if="isImmediate == 'false'">
                                <b-form-group>

                                    <label for="ScheduleDate">Date<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="ScheduleDate">
                                        <flat-pickr v-model="bulkEmail.sDate"
                                                    name="ScheduleDate"
                                                    class="form-control"
                                                    :class="errors.length > 0 ? 'error' : 'valid'"
                                                    :state="errors.length > 0 ? false : null"
                                                    :config="{altInput: true,altFormat: 'j-F-Y',dateFormat: 'd-M-Y',}" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="4" v-if="isImmediate == 'false'">
                                <b-form-group>

                                    <label for="scheduleTime">Time<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="scheduleTime">
                                        <flat-pickr v-model="scheduleTime"
                                                    nam="ScheduleTime"
                                                    class="form-control"
                                                    :state="errors.length > 0 ? false : null"
                                                    :config="{enableTime: true,noCalendar: true,dateFormat: 'H:i',}" />

                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label for="Subject">Subject<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Subject">
                                        <b-form-input id="Subject"
                                                      name="Subject"
                                                      v-model="bulkEmail.Subject"
                                                      placeholder="Please Enter Subject"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label for="EmailText">Email Body<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Email Body">
                                        <quill-editor id="EmailText"
                                                      name="EmailText"
                                                      v-model="bulkEmail.EmailText"
                                                      placeholder="Please Enter Email Body"
                                                      :options="snowOption"
                                                      :class="errors.length > 0 ? 'error' : 'valid'"
                                                      :state="errors.length > 0 ? false : null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Cam Spam Additional Footer Text(Optional)</label>
                                    <b-form-input id="CamSpam"
                                                  name="CamSpam"
                                                  v-model="bulkEmail.CamSpam"
                                                  placeholder="Give CamSpam..." />
                                </b-form-group>
                            </b-col>

                            <b-col lg="12" class="text-right" v-if="bulkEmail.IsScheduled">
                                <b-button :to="'/sendemails'"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="secondary"
                                          class="btn-icon mr-1">
                                    <feather-icon icon="ArrowLeftCircleIcon" size="18" />
                                    Go Back
                                </b-button>
                            </b-col>

                            <b-col lg="12" class="text-right" v-if="!bulkEmail.IsScheduled">
                                <b-button :to="'/sendemails'"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="secondary"
                                          class="btn-icon mr-1">
                                    <feather-icon icon="XCircleIcon" size="18" />
                                    Cancel

                                </b-button>

                                <b-button @click="openSendTestEmailModal"
                                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                          variant="info"
                                          type="button"
                                          class="mr-1">
                                    <feather-icon icon="MailIcon" size="18" />
                                    Send Test Email
                                </b-button>

                                <b-button @click="saveDraftEmail"
                                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                          variant="primary"
                                          type="button"
                                          class="mr-1">
                                    <feather-icon icon="SaveIcon" size="18" />
                                    Save a Draft
                                </b-button>

                                <b-button @click="scheduleEmail"
                                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                          variant="primary"
                                          type="button"
                                          class="mr-1">
                                    <feather-icon icon="SendIcon" size="18" />
                                    Save & Schedule
                                </b-button>
                            </b-col>
                        </b-row>
                    </form>
                </validation-observer>


                <b-modal id="sendTestEmailModal"
                         ref="modal"
                         title="Send Test Email"
                         ok-title="Submit"
                         @ok="handleEmailFormOk"
                         centered
                         size="lg">
                    <validation-observer ref="testEmailRules">
                        <form ref="TestEmailFormFunnel"
                              id="TestEmailFormFunnel"
                              @submit.stop.prevent>
                            <b-row>
                                <b-col lg="12">
                                    <b-form-group>
                                        <label for="To Email">To Email<span class="required"> *</span></label>
                                        <validation-provider #default="{ errors }"
                                                             rules="required|email"
                                                             name="Reply To Email">
                                            <b-form-input id="ToEmail"
                                                          name="ToEmail"
                                                          v-model="TestToEmail"
                                                          placeholder="Please Enter To Email"
                                                          :state="errors.length > 0 ? false : null" />
                                            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </form>
                    </validation-observer>

                    <slot></slot>
                    <template #modal-footer="{ cancel, ok }">
                        <b-button @click="closeTestEmailModal"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="secondary"
                                  type="button">
                            <feather-icon icon="XCircleIcon" size="18" />
                            Cancel
                        </b-button>

                        <b-button @click="sendTestEmail"
                                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                  variant="primary"
                                  type="button">
                            <feather-icon icon="MailIcon" size="18" />
                            Send
                        </b-button>
                    </template>
                </b-modal>
            </b-card-code>
        </section>
    </b-overlay>
</template>

<script>
    import BCardCode from "@core/components/b-card-code";
    import vSelect from "vue-select";
    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardImg,
        BRow,
        BCol,
        BCardText,
        BLink,
        BButton,
        BModal,
        VBModal,
        BFormInput,
        BForm,
        BFormRow,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BFormGroup,
        BTabs,
        BTab,
        BTable,
        BFormSelect,
        BFormCheckbox,
        BFormRadio,
        BFormFile,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormTextarea,
        BOverlay,
        BPagination,
        BAlert,
        BFormDatepicker,
        BBreadcrumb, BBreadcrumbItem,
    } from "bootstrap-vue";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        digits,
        alphaDash,
        length,
    } from "@validations";
    import { $themeConfig } from "@themeConfig";
    import { VueGoodTable } from "vue-good-table";
    import { quillEditor } from "vue-quill-editor";
    import Ripple from "vue-ripple-directive";
    import jwt from "@/auth/jwt/useJwt";
    import TimeZonesData from "@/components/TimeZones.json";
    import flatPickr from "vue-flatpickr-component";
    import axis from "axios";
    import Multiselect from "vue-multiselect";

    import { extend } from 'vee-validate';
    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });

    export default {
        metaInfo: {
            title: 'Landingly | Add Edit Email'
        },
        components: {
            vSelect,
            Multiselect,
            BFormDatepicker,
            flatPickr,
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BTabs,
            BTab,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormTextarea,
            VueGoodTable,
            BTable,
            BFormSelect,
            BFormCheckbox,
            BFormRadio,
            ValidationProvider,
            ValidationObserver,
            BOverlay,
            BPagination,
            BAlert,
            BFormFile,
            quillEditor,
            BBreadcrumb, BBreadcrumbItem,
        },
        data() {
            return {
                loading: true,
                variant: "light",
                opacity: 0.85,
                blur: "2px",
                maxLength: 45,
                meta: {
                    pageTitle: 'Configure Email',
                    breadcrumb: [
                        {
                            text: 'Emails',
                            to: '/sendemails'
                        },
                        {
                            text: 'Configure Email',
                            active: true
                        },
                    ],
                },
                dataLoaded: false,
                bulkEmail: null,
                userSmtpConfigurations: null,
                userFunnels: null,
                emailId: this.$route.params.id,
                selectedProjects: null,
                selectedProjectIds: [],
                TestToEmail: "",
                formaction: "",
                selectedOffSet: "",
                isImmediate: "true",

                error: null,
                WebAPIURL: $themeConfig.app.apiURL,
                timeZones: TimeZonesData,
                scheduleTime: null,
                scheduleDate: null,
                snowOption: {
                    theme: "snow",
                },

                selected: { title: "Square", value: "1" },
                option: [
                    { title: "Square", value: "1" },
                    { title: "Rectangle", value: "2" },
                    { title: "Rombo", value: "3" },
                    { title: "Romboid", value: "4" },
                ],
            };
        },
        directives: {
            "b-modal": VBModal,
            Ripple,
        },
        created() {
            //document.querySelector('a[href*="/sendemails"]').parentNode.classList.add("active");
            //var subDomain = jwt.getSubDomain();
            //if (subDomain == null || subDomain == "" || subDomain == 'undefined') {
            //    //this.$router.replace('/setupuseraccount')
            //    this.$router.replace('/login')
            //}
            //else {
            this.fetchBulkEmail();
            //}
        },
        beforeCreate() {
            
        },
        watch: {
            $route: "fetchBulkEmail",
        },
        computed: {
            trimmedEmailText() {
                if (this.bulkEmail.FromEmail.length > this.maxLength) {
                    return this.bulkEmail.FromEmail.substring(0, this.maxLength) + "...";
                }
                return this.bulkEmail.FromEmail;
            },
        },
        methods: {
            onTimeZoneChange(e) {
                if (e.target.options.selectedIndex > -1) {
                    const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
                    this.selectedOffSet = theTarget.offset;
                }
            },

            saveDraftEmail() {
                this.formaction = "SAVE";
                this.saveEmail();
            },

            scheduleEmail() {
                this.formaction = "SAVESCHEDULE";
                this.saveEmail();
            },

            handleEmailFormOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.saveEmail();
            },

            saveEmail: async function () {
                //this.selectedProjects.map((contact) => {
                //    this.selectedProjectIds=contact.Id+',';
                //});
                //console.log('selectedProjects:: ' + this.selectedProjectIds)

                //const formData1 = new FormData(this.$refs['bulkEmailForm']);
                //const data = {};
                //for (let [key, val] of formData1.entries()) {
                //    Object.assign(data, { [key]: val })
                //}
                //console.log(data)
                //return false;

                const access_token = await jwt.getToken();
                this.$refs.bulkEmailRules.validate().then((success) => {
                    if (success) {
                        this.loading = true;

                        var self = this;
                        var qs = require("qs");

                        let formData = new FormData();
                        for (let i = 0; i < this.selectedProjects.length; i++) {
                            this.selectedProjectIds.push(this.selectedProjects[i].Id);
                        }

                        const originalEmailText = this.bulkEmail.EmailText;
                        const textEncoder = new TextEncoder('iso-8859-1');
                        const encodedEmailText = textEncoder.encode(originalEmailText);

                        formData.append("formaction", this.formaction);
                        formData.append("Id", this.bulkEmail.Id);
                        formData.append("SmtpConfiguration", this.bulkEmail.SMTPConfigId);
                        formData.append(
                            "UserFunnelsListValues",
                            JSON.stringify(this.selectedProjectIds)
                        );
                        formData.append("FromName", this.bulkEmail.FromName);
                        formData.append("txtSubject", this.bulkEmail.Subject);
                        formData.append("EmailText", window.btoa(String.fromCharCode(...encodedEmailText)));
                        formData.append("txtReplyToEmail", this.bulkEmail.ReplyToEmail);
                        formData.append("txtCamSpam", this.bulkEmail.CamSpam);
                        formData.append("txtDelayDays", "0");
                        formData.append("txtDelayHours", "0");
                        formData.append("ddlTimeZones", this.bulkEmail.TimeZoneValue);
                        formData.append("BulkEmailDate", this.bulkEmail.sDate);
                        formData.append("BulkEmailTime", this.scheduleTime);
                        formData.append("hdTimeZoneOffSet", this.selectedOffSet);
                        formData.append("hdIsDelayed", this.bulkEmail.IsDelayed);
                        formData.append(
                            "AttachmentFolderId",
                            this.bulkEmail.AttachmentFolderId
                        );

                        axis
                            .post(
                                "" + this.WebAPIURL + "/api/emails/SendBulkEmails",
                                formData,
                                {
                                    headers: {
                                        Authorization: "bearer " + access_token,
                                        "Content-Type": "x-www-form-urlencode",
                                    },
                                }
                            )
                            .then((response) => {
                                self.loading = false;
                                //self.$router.push("/sendemails");
                                self.$router.push({ name: "sendemails" })
                            })
                            .catch((error) => {
                                this.errorMessage = error.message;
                                // console.error("There was an error!", error);
                                self.loading = false;
                            });
                    }
                });
            },

            openSendTestEmailModal: async function () {
                var self = this;
                this.$refs.bulkEmailRules.validate().then((success) => {
                    if (success) {
                        self.$nextTick(() => {
                            self.$bvModal.show("sendTestEmailModal");
                        });

                    }
                })

            },

            closeTestEmailModal() {
                this.$bvModal.hide("sendTestEmailModal");
            },

            sendTestEmail: async function () {
                const access_token = await jwt.getToken();
                this.$refs.testEmailRules.validate().then((success) => {
                    if (success) {

                        this.loading = true;

                        var self = this;
                        var qs = require("qs");
                        var toEmail = this.TestToEmail;

                        const originalEmailText = this.bulkEmail.EmailText;
                        const textEncoder = new TextEncoder('iso-8859-1');
                        const encodedEmailText = textEncoder.encode(originalEmailText);

                        let formdata = new FormData();
                        formdata.append('FromName', this.bulkEmail.FromName);
                        formdata.append('Subject', this.bulkEmail.Subject);
                        formdata.append('EmailText', window.btoa(String.fromCharCode(...encodedEmailText)));
                        formdata.append('SendToEmail', toEmail);
                        formdata.append('ReplyToEmail', this.bulkEmail.ReplyToEmail);
                        formdata.append('SmtpConfigurationId', this.bulkEmail.SMTPConfigId);
                        formdata.append('CamSpam', this.bulkEmail.CamSpam);

                        var axios = require("axios");

                        // console.log("abdData:: " + abdData);

                        axios
                            .post("" + this.WebAPIURL + "/api/projects/SendTestingEmail",
                                formdata, {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            })
                            .then(function (response) {
                                self.$nextTick(() => {
                                    self.$bvModal.hide("sendTestEmailModal");
                                });

                                self.$swal('Success',
                                    '',
                                    'success'
                                );

                                self.loading = false;

                            })
                            .catch(function (error) {
                                self.loading = false;
                                console.log(error);
                                self.$swal('Error',
                                    'Email sending failed',
                                    'error'
                                );
                                
                            });
                    }
                });
            },

            fetchBulkEmail: async function () {
                this.error = this.bulkEmails = null;
                var self = this;
                this.loading = true;
                var axios = require("axios");
                const access_token = await jwt.getToken();

                var axios = require("axios");
                var qs = require("qs");

                var data = qs.stringify({
                    Id: this.emailId,
                });
                var config = {
                    method: "get",
                    url: "" + this.WebAPIURL + "/api/emails/SendBulkEmails?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.bulkEmail = response.data;
                        self.loading = false;
                        self.dataLoaded = true;
                        self.userSmtpConfigurations = response.data.UserSmtpConfigurations;
                        self.userFunnels = response.data.UserFunnels;

                        if (self.emailId > 0) {
                            self.selectedProjects = response.data.SelectedFunnels;
                        } else {
                            self.selectedProjects = null;
                        }
                        if (self.emailId > 0) {
                            const ScheduleDate = new Date(response.data.ScheduleDate);
                            self.scheduleTime = ScheduleDate.getHours() + ":" + ScheduleDate.getMinutes();
                            self.scheduleDate = ScheduleDate.getFullYear() + "-" + (ScheduleDate.getMonth() + 1) + "-" + ScheduleDate.getDate();
                        } else {
                            const today = new Date();
                            self.scheduleDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
                            self.scheduleTime = today.getHours() + ":" + today.getMinutes();
                        }
                        if (self.emailId > 0 && response.data.IsDelayed) {
                            self.isImmediate = "false";
                        }
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                        self.dataLoaded = true;
                    });
            },
        },
        destroyed() {
            //document.querySelector('a[href*="/sendemails"]').parentNode.classList.remove("active");
        },


    };
</script>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-flatpicker.scss";
    @import "@core/scss/vue/libs/quill.scss";
    @import "@core/scss/vue/libs/vue-select.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>